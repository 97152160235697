import { Component, Input, ElementRef, ViewChild, OnDestroy, OnChanges, SimpleChanges, AfterViewChecked } from '@angular/core';

@Component({
  selector: 'app-event-map',
  templateUrl: './event-map.component.html',
  styleUrls: ['./event-map.component.css']
})
export class EventMapComponent implements OnDestroy, AfterViewChecked, OnChanges {

  @Input() height: number;
  @Input() width: number;
  @Input() imgSrc: string;
  @Input() path: { section: string, d: string, content: string } [] = [];
  @Input() viewBox: { minX: number, minY: number, width: number, height: number };
  @ViewChild('iframeTemplateSrc', { static: false }) iframe: ElementRef;

  currentMinXStep = 0;
  currentStep: number = 0;
  intervalId: any;
  
  constructor() { }

  ngAfterViewChecked(): void {
    this.iframe.nativeElement.addEventListener('load', ()=> {
      document.getElementsByTagName('body')[0].addEventListener('mouseup', () => this.iframe.nativeElement.contentWindow.postMessage({ eventName: 'mouseup' }, '*'));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.path) {
      if(changes.path.currentValue.length > 0) {
        this.iframe.nativeElement.setAttribute("height", this.height);
        this.iframe.nativeElement.setAttribute("width", this.width);
        this.iframe.nativeElement.contentWindow.loadMap(this.imgSrc, changes.path.currentValue, `${this.viewBox.minX} ${this.viewBox.minY} ${this.viewBox.width} ${this.viewBox.height}`);
      }
    }
  }

  ngOnDestroy(): void {
    document.getElementsByTagName('body')[0].removeEventListener('mouseup', () => this.iframe.nativeElement.contentWindow.postMessage({ eventName: 'mouseup' }, '*'))
  }

  zoom(zoomIn: boolean): void {

    for(var i = 1; i <= this.intervalId; i++) {
      clearInterval(i);
    }
    
    this.intervalId = setInterval(() => {
      this.currentStep = this.currentStep + 1;

      if(this.currentStep > 15) {
        this.currentStep = 0;
        clearInterval(this.intervalId);
        return;
      }

      this.iframe.nativeElement.contentWindow.zoom(new WheelEvent('wheel', { deltaY: zoomIn? -5: 5 }));

      if(this.currentStep >= 15) {
        this.currentStep = 0;
        clearInterval(this.intervalId);
      }
    }, 15);
  }

}
