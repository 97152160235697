<div class="map-container">
    <iframe 
        #iframeTemplateSrc src="/assets/common/templates/srcdoc.html" 
        title=""
        [attr.height]="height"
        [attr.width]="width">
    </iframe>
</div>
<div class="map-container-overlay">
    <div class="button-zoom">
        <div class="button-container">
            <em class="fas fa-plus-circle fa-lg" (click)="zoom(true)"></em>
            <em class="fas fa-minus-circle fa-lg" (click)="zoom(false)"></em>
        </div>
    </div>
</div>