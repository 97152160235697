import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {}

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            // new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
            new AppMenuItem('Dashboard', '', 'flaticon-line-graph', '/app/main/dashboard'),
            // new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            // new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),

            new AppMenuItem('Reports', 'Pages.Reports', 'flaticon-more', '', [], [
                new AppMenuItem('Stubhub', null, 'flaticon-map', '', [], [
                    new AppMenuItem('Scrape Report', null, 'flaticon-map', '/app/main/reports/stubhub/scrape-report')
                ]),
            ]),
            // new AppMenuItem('TicketManager', 'Pages.TicketManager', 'flaticon-more', '/app/main/ticket-manager'), // this is temporarily decommissioned because of some updates based on integration
            new AppMenuItem('Quarter Back', 'Pages.QuarterBack', 'flaticon-more', '', [], [
                new AppMenuItem('Active Events', null, 'flaticon-map', '/app/main/quarterback/live-events'),
                new AppMenuItem('All Events', null, 'flaticon-map', '/app/main/quarterback'),
                new AppMenuItem('Delivery Methods', 'Pages.QuarterBack.DeliveryMethod', 'flaticon-tabs', '/app/main/quarterback/deliverymethod/quarterback-delivery-method')
            ]),
            new AppMenuItem('Financials', 'Pages.QuarterBack.Reconciliation.Workspace', 'flaticon-more', '', [], [
                new AppMenuItem("Payables", null, "flaticon-more", '', [], [
                    new AppMenuItem("Workspace Reconciler", 'Pages.QuarterBack.Reconciliation.Workspace', 'flaticon-suitcase', '/app/main/quarterback/reconciliation/workspace'),
                    new AppMenuItem("Credit Card Statements", 'Pages.QuarterBack.Reconciliation.Charges', 'flaticon-suitcase', '/app/main/quarterback/reconciliation/transaction'),
                    new AppMenuItem('Purchase Orders', 'Pages.QuarterBack.Reconciliation.Inventories', 'flaticon-suitcase', '/app/main/quarterback/inventories/quarterback-reconciliation-inventory')
                ]),
                new AppMenuItem("Receivables", null, "flaticon-more", '', [], [
                    // new AppMenuItem("Workspace Reconciler", 'Pages.QuarterBack.Reconciliation.Workspace', 'flaticon-suitcase', ''),  // since nothing is definite yet on the business rules for AR reconciler, this is temporarily decommissioned.
                    new AppMenuItem('Market Exchanges', 'Pages.QuarterBack.Reconciliation.Inventories', 'flaticon-suitcase', '/app/main/quarterback/reconciliation/market-exchange/view-market-exchange-files/view-market-exchange-files')
                ]),
                new AppMenuItem("Management", null, "flaticon-more", '', [], [
                    new AppMenuItem("Credit Cards", 'Pages.QuarterBack.Reconciliation.CreditCards', 'flaticon-suitcase', '/app/main/quarterback/creditcard/creditcard'),
                    // new AppMenuItem('Credit Card Companies', 'Pages.QuarterBack.Reconciliation.Inventories', 'flaticon-suitcase', ''),
                    new AppMenuItem('Market Exchange File Templates', 'Pages.QuarterBack.Reconciliation.Inventories', 'flaticon-suitcase', '/app/main/quarterback/reconciliation/market-exchange/market-exchange-file-template/view-market-exchange-file-template'),
                    new AppMenuItem('Purchase Order File Templates', 'Pages.QuarterBack.Reconciliation.Inventories', 'flaticon-suitcase', ''),
                    new AppMenuItem("Global Rewards Cards", 'Pages.QuarterBack.Reconciliation.CreditCards', 'flaticon-suitcase', '/app/main/quarterback/global-rewards-cards'),
                    new AppMenuItem("Global Rewards Transactions", 'Pages.QuarterBack.Reconciliation.CreditCards', 'flaticon-suitcase', '/app/main/quarterback/global-rewards-transactions'),
                    new AppMenuItem("ComData Transactions", 'Pages.QuarterBack.Reconciliation.CreditCards', 'flaticon-suitcase', '/app/main/quarterback/comdata/transactions'),
                    new AppMenuItem("ComData Payment Cards", 'Pages.QuarterBack.Reconciliation.CreditCards', 'flaticon-suitcase', '/app/main/quarterback/comdata/payment-cards'),
                    new AppMenuItem("Divvy Transactions", 'Pages.QuarterBack.Reconciliation.CreditCards', 'flaticon-suitcase', '/app/main/quarterback/divvy/transactions'),
                ])
            ]),
            // anything about StubHub (including its integration) is currently decommissioned. This is the impact with the new API that StubHub roled out.
            // new AppMenuItem('Scrapers', 'Pages.Scrapers', 'flaticon-more', '', [], [
            //     new AppMenuItem("Stubhub", null, "flaticon-more", '', [], [
            //         new AppMenuItem("List and Sale Scraper", null, "flaticon-book", "/app/main/scrapers/stubhub/stubhub-list-sale-scraper"),
            //         new AppMenuItem("Full Catalog Scraper", null, "flaticon-book", "/app/main/scrapers/stubhub/stubhub-full-catalog-scraper")
            //     ])
            // ]),
            new AppMenuItem('Amex Credit Cards', 'Pages.AmexAutoPay', 'flaticon-more', "/app/main/amex-auto-pay/list-component", []),
            new AppMenuItem('Administration', '', 'flaticon-interface-8', '', [], [
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                // new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages'),
                // new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                // new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                // new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                new AppMenuItem("Amex Autopay", null, "flaticon-more", '', [], [
                    new AppMenuItem("Amex Key Value", 'Pages.Administration.AmexAutoPay.AmexKeyValue', 'flaticon-suitcase', '/app/admin/amex-autopay/amex-key-value'),
                ]),
                new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('WebhookSubscriptions', 'Pages.Administration.WebhookSubscription', 'flaticon2-world', '/app/admin/webhook-subscriptions'),
                new AppMenuItem('DynamicProperties', 'Pages.Administration.DynamicProperties', 'flaticon-interface-8', '/app/admin/dynamic-property'),                // new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                //new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings')
            ]),
            // new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/demo-ui-components')
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
